import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import Lessons from "./pages/Lessons";
import Study from "./pages/Study";
import Practice from "./pages/Practice";
import AddContent from "./pages/AddContent";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Countdown from "./pages/Countdown";
import CountdownWeeks from "./pages/CountdownWeeks";
import GetIP from "./pages/GetIP";
import SessionCheck from "./pages/SessionCheck";
import SessionCheck2 from "./pages/SessionCheck2";
import Tasks from "./pages/Tasks";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <SessionCheck>
            <Main />
          </SessionCheck>
        }
      />
      <Route
        path="/:language/lessons"
        element={
          <SessionCheck>
            <Lessons />
          </SessionCheck>
        }
      />
      <Route
        path="/:language/study/:lessonName"
        element={
          <SessionCheck>
            <Study />
          </SessionCheck>
        }
      />
      <Route
        path="/:language/practice/:lessonName"
        element={
          <SessionCheck>
            <Practice />
          </SessionCheck>
        }
      />
      <Route
        path="/:language/addcontent"
        element={
          <SessionCheck>
            <AddContent />
          </SessionCheck>
        }
      />
      <Route
        path="/login"
        element={
          <SessionCheck2>
            <Login />
          </SessionCheck2>
        }
      />
      <Route
        path="/signup"
        element={
          <SessionCheck2>
            <SignUp />
          </SessionCheck2>
        }
      />
      <Route
        path="/countdown"
        element={
          <SessionCheck>
            <Countdown />
          </SessionCheck>
        }
      />
      <Route
        path="/countdown/weeks"
        element={
          <SessionCheck>
            <CountdownWeeks />
          </SessionCheck>
        }
      />
      <Route
        path="/tasks"
        element={
          <SessionCheck>
            <Tasks />
          </SessionCheck>
        }
      />
      <Route path="/ip" element={<GetIP />} />
    </Routes>
  );
}

export default App;
