import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SessionCheck2 = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Loading state
  let executed = false;

  const checksession = async () => {
    if (!executed) {
      executed = true;
      try {
        const response = await fetch("/php/session.php", {
          method: "GET",
          credentials: "include",
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(
            `HTTPS error! Status: ${response.status}. Message: ${errorText}`
          );
        }
        const result = await response.text();
        //console.log(result);

        // Redirect to "/" if the session is valid
        if (result === "ok") {
          navigate("/");
        }
      } catch (error) {
        //console.error(error);
        // Allow access on connection error
        // Optionally, you can add a different error handling here if needed
      } finally {
        setLoading(false); // Set loading to false once the check is complete
      }
    }
  };

  useEffect(() => {
    checksession();
  }, [navigate]);

  // Spinner Component
  const Spinner = () => (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );

  // CSS Styles for Spinner
  const spinnerStyles = {
    spinnerContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh", // Full viewport height
    },
    spinner: {
      border: "8px solid #f3f3f3", // Light grey
      borderTop: "8px solid #3498db", // Blue
      borderRadius: "50%",
      width: "50px", // Size of the spinner
      height: "50px", // Size of the spinner
      animation: "spin 1s linear infinite", // Animation properties
    },
    keyframes: {
      "@keyframes spin": {
        "0%": { transform: "rotate(0deg)" },
        "100%": { transform: "rotate(360deg)" },
      },
    },
  };

  // Inject keyframes into the document
  const styleSheet = document.styleSheets[0];
  const keyframesRule = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;
  styleSheet.insertRule(keyframesRule, styleSheet.cssRules.length);

  // If loading, show the spinner
  if (loading) {
    return <Spinner />;
  }

  // Render children (the protected component) once loading is complete
  return <>{children}</>;
};

export default SessionCheck2;
