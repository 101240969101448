import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaCheck, FaTimes, FaPlus } from "react-icons/fa"; // Importing icons from react-icons

function Tasks() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Loading state
  const [tasks, setTasks] = useState([]);
  const [taskName, setTaskName] = useState(""); // State for new task name
  const [deadline, setDeadline] = useState(""); // State for optional deadline
  let executed = false;

  const getTasks = async () => {
    if (!executed) {
      executed = true;
      try {
        const response = await fetch("/php/gettasks.php", {
          method: "GET",
          credentials: "include",
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(
            `HTTPS error! Status: ${response.status}. Message: ${errorText}`
          );
        }
        const result = await response.text();
        if (result === "mt") {
          // navigate("/login");
        } else {
          const parsedTasks = JSON.parse(result); // Parse the result as JSON
          setTasks(parsedTasks); // Set the parsed tasks in state
        }
      } catch (error) {
        // console.error(error);
        // navigate("/login");
      } finally {
        setLoading(false); // Set loading to false once the check is complete
      }
    }
  };

  useEffect(() => {
    getTasks();
  }, [navigate]);

  const updateTask = async (taskOrder, currentCheckedState) => {
    try {
      // Determine the new checked state
      const newCheckedState = currentCheckedState === 1 ? 0 : 1;

      // Send the update request with the new state
      const response = await fetch("/php/checktask.php", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          task_order: taskOrder,
          checked: newCheckedState, // Send the new checked state
        }),
      });

      const result = await response.text();
      console.log("ut" + result); // Handle the response as needed

      // Update local state to reflect the new checked status
      setTasks(
        tasks.map((task) =>
          task.task_order === taskOrder
            ? { ...task, checked: newCheckedState }
            : task
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (taskId) => {
    try {
      const response = await fetch("/php/deletetask.php", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          task_order: taskId, // Send the task ID to be deleted
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to delete task: ${errorText}`);
      }

      const result = await response.text();
      console.log("hd" + result); // Optionally log the result

      // Update state to remove the task from the UI
      setTasks(tasks.filter((task) => task.task_order !== taskId));
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddTask = async (e) => {
    e.preventDefault(); // Prevent form submission

    // Determine the next task_order
    const nextTaskOrder =
      tasks.length > 0
        ? Math.max(...tasks.map((task) => task.task_order)) + 1
        : 1;

    try {
      const response = await fetch("/php/inserttask.php", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          task: taskName,
          task_order: nextTaskOrder,
          deadline: deadline || "", // Send deadline if provided
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to add task: ${errorText}`);
      }

      const result = await response.text();
      console.log("hat" + result);

      // Refresh tasks after adding
      getTasks();

      // Reset input fields
      setTaskName("");
      setDeadline("");
    } catch (error) {
      console.error(error);
    }
  };

  // Spinner Component
  const Spinner = () => (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );

  // If loading, show the spinner
  if (loading) {
    return (
      <div
        className="main"
        style={{ color: "white", textAlign: "center", padding: "2em" }}
      >
        <Spinner />
      </div>
    );
  }

  // Render children (the protected component) once loading is complete
  return (
    <div
      className="main"
      style={{
        color: "white",
        textAlign: "center",
        fontSize: "3em",
      }}
    >
      <h1 style={{ marginBottom: "0px" }}>Tasks</h1>

      <ul style={{ listStyleType: "none", padding: 0 }}>
        {tasks.map((task) => (
          <li
            key={task.task_order}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaCheck
                onClick={() => updateTask(task.task_order, task.checked)} // Pass the current checked state
                style={{
                  cursor: "pointer",
                  color: task.checked ? "green" : "gray",
                }}
              />
              <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                <strong>{task.task}</strong>
                {task.deadline !== 0 && (
                  <>
                    Deadline: <strong>{task.deadline}</strong>
                  </>
                )}
              </span>
            </div>
            <FaTimes
              onClick={() => handleDelete(task.task_order)}
              style={{ cursor: "pointer", color: "red" }}
            />
          </li>
        ))}
      </ul>
      {/* Form for adding a new task */}
      <form onSubmit={handleAddTask} style={{ marginBottom: "20px" }}>
        <input
          type="text"
          placeholder="Task Name"
          value={taskName}
          onChange={(e) => setTaskName(e.target.value)}
          required
        />
        <input
          type="date"
          value={deadline}
          onChange={(e) => setDeadline(e.target.value)}
        />
        <button type="submit" style={{ marginLeft: "10px" }}>
          <FaPlus /> Add Task
        </button>
      </form>
    </div>
  );
}

export default Tasks;
