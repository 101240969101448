import React, { useState, useEffect } from 'react';

function Countdown() {
  const calculateTimeLeft = () => {
    const now = new Date();
    const targetDate = new Date("2025-01-26T00:00:00");
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      const years = targetDate.getFullYear() - now.getFullYear();
      const months = targetDate.getMonth() - now.getMonth() + (years * 12);
      const days = targetDate.getDate() - now.getDate();

      // Handle days wrap-around
      if (days < 0) {
        const previousMonth = new Date(targetDate.getFullYear(), targetDate.getMonth(), 0);
        timeLeft = {
          months: months - 1,
          days: previousMonth.getDate() + days,
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      } else {
        timeLeft = {
          months,
          days,
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <div className='main' style={{color: 'white'}}>
      <h1 style={{fontSize: '8vh'}}>Countdown</h1>
      <div style={{fontSize: '6vh'}}>
        {timeLeft.months || '0'} Months {timeLeft.days || '0'} Days {timeLeft.hours || '0'} Hours{' '}
        {timeLeft.minutes || '0'} Minutes {timeLeft.seconds || '0'} Seconds
      </div>
    </div>
  );
}

export default Countdown;
