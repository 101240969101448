// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes slideRadialGradient2 {
  0% {
    background-position: -100vw 0%;
  }
  100% {
    background-position: -50vw 0%;
  }
}

.animated-study {
  height: 100vh;
  width: 100%;
  background: radial-gradient(
    circle at 125vw 50vh,
    #86586a 3%,
    #20392c 10%,
    #141416 18%,
    #141416
  );
  background-size: 300vw 300vh;
  background-repeat: repeat;
  animation: slideRadialGradient2 5s ease-in-out forwards;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/study.css"],"names":[],"mappings":"AAAA;EACE;IACE,8BAA8B;EAChC;EACA;IACE,6BAA6B;EAC/B;AACF;;AAEA;EACE,aAAa;EACb,WAAW;EACX;;;;;;GAMC;EACD,4BAA4B;EAC5B,yBAAyB;EACzB,uDAAuD;EACvD,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":["@keyframes slideRadialGradient2 {\n  0% {\n    background-position: -100vw 0%;\n  }\n  100% {\n    background-position: -50vw 0%;\n  }\n}\n\n.animated-study {\n  height: 100vh;\n  width: 100%;\n  background: radial-gradient(\n    circle at 125vw 50vh,\n    #86586a 3%,\n    #20392c 10%,\n    #141416 18%,\n    #141416\n  );\n  background-size: 300vw 300vh;\n  background-repeat: repeat;\n  animation: slideRadialGradient2 5s ease-in-out forwards;\n  margin: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
