// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes slideRadialGradient4 {
  0% {
    background-position: -25vw -50vh;
  }
  100% {
    background-position: 0vw -50vh;
  }
}

.animated-addcontent {
  height: 100vh;
  width: 100%;
  background-color: #141416;
  background: radial-gradient(
      circle at 125vw 50vh,
      #86586a 3%,
      #20392c 10%,
      #141416 18%,
      transparent 19%
    ),
    radial-gradient(
      circle at 50vw 150vh,
      #86586a 2%,
      #20392c 6%,
      #141416 12%,
      transparent 13%
    ),
    radial-gradient(
      circle at 5vw 50vh,
      #86586a 2%,
      #20392c 6%,
      #141416 12%,
      #141416 13%
    );
  background-size: 300vw 300vh;
  background-repeat: repeat;
  animation: slideRadialGradient4 5s ease-in-out forwards;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.test {
  height: 100vh;
  width: 100vw;
  background-color: #232221;
  background-image: radial-gradient(
      circle at 70% 90%,
      #86586a 10rem,
      #20392c 10rem,
      #141416 15rem,
      transparent 15rem
    ),
    radial-gradient(
      circle at 10% 20%,
      transparent 10rem,
      #86586a 10rem,
      #20392c 15rem,
      transparent 15rem
    );
  background-size: 100vw 100vh;
}
`, "",{"version":3,"sources":["webpack://./src/pages/addcontent.css"],"names":[],"mappings":"AAAA;EACE;IACE,gCAAgC;EAClC;EACA;IACE,8BAA8B;EAChC;AACF;;AAEA;EACE,aAAa;EACb,WAAW;EACX,yBAAyB;EACzB;;;;;;;;;;;;;;;;;;;;KAoBG;EACH,4BAA4B;EAC5B,yBAAyB;EACzB,uDAAuD;EACvD,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB;;;;;;;;;;;;;KAaG;EACH,4BAA4B;AAC9B","sourcesContent":["@keyframes slideRadialGradient4 {\n  0% {\n    background-position: -25vw -50vh;\n  }\n  100% {\n    background-position: 0vw -50vh;\n  }\n}\n\n.animated-addcontent {\n  height: 100vh;\n  width: 100%;\n  background-color: #141416;\n  background: radial-gradient(\n      circle at 125vw 50vh,\n      #86586a 3%,\n      #20392c 10%,\n      #141416 18%,\n      transparent 19%\n    ),\n    radial-gradient(\n      circle at 50vw 150vh,\n      #86586a 2%,\n      #20392c 6%,\n      #141416 12%,\n      transparent 13%\n    ),\n    radial-gradient(\n      circle at 5vw 50vh,\n      #86586a 2%,\n      #20392c 6%,\n      #141416 12%,\n      #141416 13%\n    );\n  background-size: 300vw 300vh;\n  background-repeat: repeat;\n  animation: slideRadialGradient4 5s ease-in-out forwards;\n  margin: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.test {\n  height: 100vh;\n  width: 100vw;\n  background-color: #232221;\n  background-image: radial-gradient(\n      circle at 70% 90%,\n      #86586a 10rem,\n      #20392c 10rem,\n      #141416 15rem,\n      transparent 15rem\n    ),\n    radial-gradient(\n      circle at 10% 20%,\n      transparent 10rem,\n      #86586a 10rem,\n      #20392c 15rem,\n      transparent 15rem\n    );\n  background-size: 100vw 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
