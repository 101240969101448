import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import cat from "../img/login_cat.svg";
import "./login.css";

function Login() {
  const [userOrEmail, setUserOrEmail] = useState("");
  const [password, setPassword] = useState("");
  const [buttonHover, setButtonHover] = useState(false);
  const [userOrEmailComment, setUserOrEmailComment] = useState("");
  const [passwordComment, setPasswordComment] = useState("");
  const [serverError, setServerError] = useState("");
  const navigate = useNavigate();

  const handleUserOrEmail = (event) => {
    setUserOrEmail(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleLogIn = async () => {
    if (userOrEmail) {
      setUserOrEmailComment("");
      if (password) {
        const data = { user_email: userOrEmail, password: password };
        try {
          setUserOrEmailComment("");
          setPasswordComment("");
          setServerError("");
          const response = await fetch("/php/login.php", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          });

          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(
              `HTTPS error! Status: ${response.status}. Message: ${errorText}`
            );
          }

          const result = await response.text(); // Using text() to get the raw response
          // console.log(result);
          if (result.slice(0, 1) === "u") {
            setUserOrEmailComment(result.slice(1));
          } else if (result.slice(0, 1) === "s") {
            setServerError(result.slice(1));
          } else {
            navigate("/");
          }
        } catch (error) {
          // alert(`An error occurred: ${error.message}`); // Display error to user
        }
      } else if (!password) {
        setPasswordComment("Password field required");
      }
    } else {
      setUserOrEmailComment("This field is required");
    }
  };

  return (
    <div
      className="animated-login"
      style={{ display: "flex", flex: "1", flexDirection: "column" }}
    >
      <img
        src={cat}
        alt="login_cat"
        style={{ height: "160px", width: "auto", marginTop: "4vh" }}
      />
      <div style={{ display: "flex", flex: "1", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            border: "3px solid white",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "35px",
            background: "#1A2E23",
            marginTop: "-0.3vh",
            marginBottom: "4vh",
          }}
        >
          <p
            style={{
              color: "#ffffff",
              fontSize: "9vh",
              fontWeight: "700",
              padding: "0",
              margin: "0",
              marginTop: "3vh",
            }}
          >
            CATLINGO
          </p>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              autoComplete="username"
              className="userOrEmail"
              type="text"
              placeholder="Username or Email"
              onChange={handleUserOrEmail}
              value={userOrEmail}
              style={{
                display: "flex",
                fontSize: "3vh",
                color: "#ffffff",
                height: "8vh",
                width: "30vw",
                border: "0px solid",
                borderRadius: "35px",
                backgroundColor: "#294033",
                alignContent: "center",
                textAlign: "left",
                marginLeft: "3vw",
                marginRight: "3vw",
                marginTop: "2vh",
                paddingLeft: "2vw",
                paddingRight: "2vw",
              }}
            />
            {userOrEmailComment && (
              <p
                style={{
                  color: "#de4d43",
                  margin: "0",
                  marginTop: "0.4vh",
                  marginBottom: "0vh",
                  fontSize: "1.5vh",
                }}
              >
                {userOrEmailComment}
              </p>
            )}
            <input
              autoComplete="current-password"
              className="password"
              type="password"
              placeholder="Password"
              onChange={handlePassword}
              value={password}
              style={{
                display: "flex",
                fontSize: "3vh",
                color: "#ffffff",
                height: "8vh",
                width: "30vw",
                border: "0px solid",
                borderRadius: "35px",
                backgroundColor: "#294033",
                alignContent: "center",
                textAlign: "left",
                marginLeft: "3vw",
                marginRight: "3vw",
                marginTop: "3vh",
                paddingLeft: "2vw",
                paddingRight: "2vw",
              }}
            />
            {passwordComment && (
              <p
                style={{
                  color: "#de4d43",
                  margin: "0",
                  marginTop: "0.5vh",
                  marginBottom: "-2.2vh",
                  fontSize: "1.5vh",
                }}
              >
                {passwordComment}
              </p>
            )}
          </form>
          <button
            onClick={handleLogIn}
            onMouseEnter={() => setButtonHover(true)}
            onMouseLeave={() => setButtonHover(false)}
            style={{
              display: "flex",
              fontSize: "4vh",
              fontWeight: "600",
              color: buttonHover ? "#ffffff" : "#dadada",
              height: "8vh",
              width: "20vw",
              border: "3px solid",
              borderRadius: "35px",
              backgroundColor: buttonHover ? "#c493ac" : "#8c687a",
              alignContent: "center",
              textAlign: "center",
              marginLeft: "3vw",
              marginRight: "3vw",
              marginTop: "4vh",
              marginBottom: "1vh",
              padding: "0",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            LOG IN
          </button>
          <a style={{ color: "white", fontSize: "2.5vh", margin: "2vh" }}>
            Forgot Password?
          </a>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            border: "3px solid white",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "35px",
            background: "#1A2E23",
            color: "white",
            fontSize: "3vh",
          }}
        >
          <p style={{ paddingRight: "1vw" }}>Don't have an account?</p>
          <a href={"/signup"} style={{ color: "#b9a" }}>
            Sign Up
          </a>
        </div>
      </div>
    </div>
  );
}

export default Login;
