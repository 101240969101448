import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import cat from "../img/welcome_cat.svg";

function Main() {
  const [languages, setLanguages] = useState([]);
  const [error, setError] = useState(null);
  const [hovered, setHovered] = useState({}); // Object to hold hover states
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await fetch('php/languages.php');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setLanguages(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchLanguages();
  }, []);

  const buttonStyle = {
    width: "8em",
    height: "3em",
    fontSize: "2.5em",
    borderRadius: "5em",
    borderWidth: "0.1em",
    color: "#F4F2E9",
    marginRight: "0.6em",
    borderStyle: "solid",
    transition: "background-image 1s ease, border-color 0.5s ease",
  };

  const handleClick = (language) => {
    navigate(`/${language}/lessons`);
  };

  return (
    <div
      className="main"
      style={{
        color: "#ffffff",
        padding: "1em",
        display: "flex",
        flex: "1",
        width: "100%",
        margin: "0",
        flexDirection: "column", // Added to stack items vertically
        alignItems: "center",     // Center align items
      }}
    >
      <img
        src={cat}
        alt="welcome_cat"
        style={{ height: "270px", width: "auto" }}
      />
      <h1 style={{ fontSize: "5.5em", marginBottom: 0, marginTop: "0.2em" }}>
        WELCOME TO
        <br /> LANGUAGE CENTER.
      </h1>
      <h4
        style={{
          fontSize: "2.5em",
          color: "#F4F2E9",
          fontWeight: "lighter",
          marginTop: "0",
        }}
      >
        Which language would you like to learn today?
      </h4>
      <div
        className="buttons"
        style={{
          flexDirection: "row",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {languages.map((language, index) => (
          <button
            key={index}
            style={{
              ...buttonStyle,
              backgroundImage: hovered[language.language]
                ? "linear-gradient(to right, #1A2E23, #315742)"
                : "linear-gradient(to right, #315742, #1A2E23)",
              borderColor: hovered[language.language] ? "#282832" : "#F4F2E9",
            }}
            onMouseEnter={() => setHovered(prev => ({ ...prev, [language.language]: true }))}
            onMouseLeave={() => setHovered(prev => ({ ...prev, [language.language]: false }))}
            onClick={() => handleClick(language.language)} // Use the dynamic language name
          >
            {language.language.charAt(0).toUpperCase() + language.language.slice(1)} {/* Display the language name */}
          </button>
        ))}
      </div>
      {error && <p style={{ color: "red" }}>{error}</p>} {/* Display error if any */}
    </div>
  );
}

export default Main;
