import React, { useState, useEffect } from 'react';

function CountdownWeeks() {
  const targetDate = new Date("2025-01-25T00:00:00");

  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      const totalSeconds = Math.floor(difference / 1000);
      const totalDays = Math.floor(totalSeconds / (60 * 60 * 24));
      const weeks = Math.floor(totalDays / 7);
      const days = totalDays % 7;

      timeLeft = {
        weeks,
        days,
        hours: Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60)),
        minutes: Math.floor((totalSeconds % (60 * 60)) / 60),
        seconds: totalSeconds % 60,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <div className='main' style={{ color: 'white', textAlign: 'center', padding: '2em' }}>
      <h1 style={{ fontSize: '8vh' }}>Countdown</h1>
      <div style={{ fontSize: '6vh' }}>
        {timeLeft.weeks || '0'} Weeks {timeLeft.days || '0'} Days {timeLeft.hours || '0'} Hours{' '}
        {timeLeft.minutes || '0'} Minutes {timeLeft.seconds || '0'} Seconds
      </div>
    </div>
  );
}

export default CountdownWeeks;
