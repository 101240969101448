import React, { useState } from "react";
import "./addcontent.css";
import { FaPlus, FaCheck, FaArrowRight } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

function AddContent() {
  const navigate = useNavigate();
  const { language } = useParams();
  const [contentList, setContentList] = useState([]);
  const [title, setTitle] = useState("");
  const [lessonID, setLessonID] = useState(null);
  const [newEntry, setNewEntry] = useState({
    rofil: "",
    english: "",
    example: "",
    exen: "",
  });
  const [isEnteringContent, setIsEnteringContent] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewEntry((prev) => ({ ...prev, [name]: value }));
  };

  const handleTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleSaveTitle = async () => {
    const data = {
      language,
      title,
    };

    try {
      // Save the lesson title first
      const response = await fetch("/php/savelessontitle.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (result.success) {
        console.log("Title saved successfully:", result);

        // Fetch the lesson_ID after saving the title
        const lessonIdResponse = await fetch("/php/getlessonid.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ language, title }), // Pass the title to get the lesson_ID
        });

        const lessonIdResult = await lessonIdResponse.json();
        if (lessonIdResult.success) {
          setLessonID(lessonIdResult.lesson_ID); // Store lesson_ID in state
          setIsEnteringContent(true);
          console.log("Lesson ID fetched:", lessonIdResult.lesson_ID);
        } else {
          console.error("Error fetching lesson ID:", lessonIdResult.message);
        }
      } else {
        console.error("Error saving title:", result.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAddEntry = () => {
    const newEntryToAdd = {
      word: newEntry.rofil,
      english: newEntry.english,
      example: newEntry.example,
      english_example: newEntry.exen,
    };

    setContentList((prev) => [...prev, newEntryToAdd]); // Use a functional update to ensure the latest state is used
    setNewEntry({ rofil: "", english: "", example: "", exen: "" });
  };

  const handleSaveContent = async () => {
    if (!lessonID) {
      console.error("Error: lesson_ID is missing");
      return;
    }

    // Add the new entry to the content list here
    const entry = {
      word: newEntry.rofil,
      english: newEntry.english,
      example: newEntry.example,
      english_example: newEntry.exen,
    };

    // Set contentList with the new entry
    const updatedContentList = [...contentList, entry];

    const data = {
      language,
      lesson_ID: lessonID,
      contentList: updatedContentList, // Use the updated content list
    };

    try {
      console.log(data);
      const response = await fetch("/php/savelesson.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (result.success) {
        console.log("Content saved successfully:", result);
        navigate(`/${language}/lessons`);
      } else {
        console.error("Error saving content:", result.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div
      className="animated-addcontent"
      style={{
        color: "#ffffff",
        padding: "1em",
        display: "flex",
        flex: "1",
        width: "100%",
        margin: "0",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="title" style={{ display: "flex" }}>
        <h1 style={{ fontSize: "8vh", marginBottom: 0, marginTop: "0.2em" }}>
          ADD MORE CONTENT
        </h1>
      </div>
      <div
        style={{
          display: "flex",
          flex: "1",
          width: "100%",
          flexDirection: "column",
          paddingBottom: "1em",
          marginBottom: "4em",
          marginRight: "2em",
        }}
      >
        {!isEnteringContent ? (
          <div
            style={{
              display: "flex",
              flex: "1",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <input
              className="rofil"
              type="text"
              placeholder="Enter Lesson Title"
              onChange={handleTitle}
              value={title}
              style={{
                display: "flex",
                flex: "1",
                fontSize: "4.5vh",
                color: "#ffffff",
                height: "15vh",
                width: "90vw",
                border: "3px solid",
                borderRadius: "35px",
                backgroundColor: "#3D6C54",
                alignContent: "center",
                textAlign: "center",
                marginLeft: "5vw",
                marginRight: "5vw",
                marginTop: "5vh",
                padding: "0",
              }}
            />
          </div>
        ) : (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <input
                className="rofil"
                type="text"
                name="rofil"
                placeholder={
                  language.charAt(0).toUpperCase() + language.slice(1)
                }
                onChange={handleChange}
                value={newEntry.rofil}
                style={{
                  display: "flex",
                  flex: "1",
                  fontSize: "4.5vh",
                  color: "#ffffff",
                  height: "15vh",
                  width: "90vw",
                  border: "3px solid",
                  borderRadius: "35px",
                  backgroundColor: "#b9a",
                  alignContent: "center",
                  textAlign: "center",
                  marginLeft: "5vw",
                  marginRight: "2vw",
                  marginTop: "5vh",
                  padding: "0",
                }}
              />
              <input
                className="english"
                type="text"
                name="english"
                placeholder="English"
                onChange={handleChange}
                value={newEntry.english}
                style={{
                  display: "flex",
                  flex: "1",
                  fontSize: "4.5vh",
                  color: "#ffffff",
                  height: "15vh",
                  width: "90vw",
                  border: "3px solid",
                  borderRadius: "35px",
                  backgroundColor: "#86586A",
                  alignContent: "center",
                  textAlign: "center",
                  marginRight: "4.5vw",
                  marginTop: "5vh",
                  padding: "0",
                }}
              />
            </div>
            <input
              className="rofil"
              type="text"
              name="example"
              placeholder="Example Sentence"
              onChange={handleChange}
              value={newEntry.example}
              style={{
                display: "flex",
                flex: "1",
                fontSize: "4.5vh",
                color: "#ffffff",
                height: "15vh",
                width: "90vw",
                border: "3px solid",
                borderRadius: "35px",
                backgroundColor: "#3D6C54",
                alignContent: "center",
                textAlign: "center",
                marginLeft: "5vw",
                marginRight: "5vw",
                marginTop: "5vh",
                padding: "0",
              }}
            />
            <input
              className="rofil"
              type="text"
              name="exen"
              placeholder="English Example Sentence"
              onChange={handleChange}
              value={newEntry.exen}
              style={{
                display: "flex",
                flex: "1",
                fontSize: "4.5vh",
                color: "#ffffff",
                height: "15vh",
                width: "90vw",
                border: "3px solid",
                borderRadius: "35px",
                backgroundColor: "#1A2E23",
                alignContent: "center",
                textAlign: "center",
                marginLeft: "5vw",
                marginRight: "5vw",
                marginTop: "5vh",
                padding: "0",
              }}
            />
          </>
        )}
        <div />
        {!isEnteringContent ? (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <button
              type="submit"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "4.5vh",
                color: "#ffffff",
                height: "10vh",
                width: "10.3vw",
                border: "3px solid",
                borderRadius: "25px",
                backgroundImage: "linear-gradient(to right, #86586A, #EEC7C7)",
                alignContent: "center",
                textAlign: "center",
                marginLeft: "85vw",
                marginRight: "2vw",
                marginTop: "5vh",
                padding: "0",
              }}
              onClick={handleSaveTitle}
            >
              <FaArrowRight />
            </button>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <button
              onClick={handleAddEntry}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "4.5vh",
                color: "#ffffff",
                height: "10vh",
                width: "20.3vw",
                border: "3px solid",
                borderRadius: "25px",
                backgroundImage: "linear-gradient(to right, #86586A, #EEC7C7)",
                alignContent: "center",
                textAlign: "center",
                marginLeft: "65vw",
                marginRight: "2vw",
                marginTop: "5vh",
                padding: "0",
              }}
            >
              <FaPlus />
            </button>
            <button
              onClick={handleSaveContent}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "4.5vh",
                color: "#ffffff",
                height: "10vh",
                width: "20.3vw",
                border: "3px solid",
                borderRadius: "25px",
                backgroundImage: "linear-gradient(to right, #86586A, #EEC7C7)",
                alignContent: "center",
                textAlign: "center",
                marginRight: "4.5vw",
                marginTop: "5vh",
                padding: "0",
              }}
            >
              <FaCheck />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddContent;
