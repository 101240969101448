import React, { useState, useEffect } from "react";
import "./practice.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function Practice() {
  const [answer, setAnswer] = useState("");
  const [check, setCheck] = useState(false);
  const [result, setResult] = useState(false);
  const [answerRef, setAnswerRef] = useState("");
  const [practiceContent, setPracticeContent] = useState(null);
  const { language, lessonName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const practiceLanguage = Math.random() < 0.5;
  const { lessonNumber, title, ex_ID } = location.state || {};

  const fetchContent = async () => {
    try {
      const response = await fetch("/php/practice.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ language, lesson_ID: lessonNumber, ex_ID }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          `Network response was not ok: ${errorData.error || "Unknown error"}`
        );
      }
      const data = await response.json();
      if (Array.isArray(data) && data.length > 0) {
        setPracticeContent(data[0].word);
        setAnswerRef(data[0].answer);
      } else {
        // If there's no data, it means we've reached the last exercise
        navigate(`/${language}/lessons`); // Redirect to lessons page
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchContent();
  }, [language, lessonNumber, title, ex_ID]);

  const handleNextClick = () => {
    setAnswer("");
    setCheck(false);
    setResult(false);
    setAnswerRef("");

    // Proceed to the next exercise
    navigate(`/${language}/practice/${lessonName}`, {
      state: { lessonNumber, title, ex_ID: ex_ID + 1 },
    });
  };

  const handleChange = (event) => {
    setAnswer(event.target.value);
  };

  const handleCheck = () => {
    setResult(answer.toLowerCase() === answerRef.toLowerCase());
    setCheck(true);
  };

  // Handle key down event for Enter key
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission if inside a form
      check ? handleNextClick() : handleCheck();
    }
  };

  return (
    <div
      className="animated-practice"
      style={{
        color: "#ffffff",
        padding: "1em",
        display: "flex",
        flex: "1",
        width: "100%",
        margin: "0",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="title" style={{ display: "flex" }}>
        <h1 style={{ fontSize: "8vh", marginBottom: 0, marginTop: "0.2em" }}>
          PRACTICE -{" "}
          {lessonName.replace(/-/g, " ").replace(/^\w/, (c) => c.toUpperCase())}
        </h1>
      </div>
      <div
        style={{
          display: "flex",
          flex: "1",
          width: "100%",
          flexDirection: "column",
          paddingBottom: "1em",
          marginBottom: "4em",
          marginRight: "2em",
        }}
      >
        <div
          className="prompt"
          style={{
            border: "3px solid",
            width: "90vw",
            borderRadius: "25px",
            backgroundColor: "#3D6C54",
            alignContent: "center",
            textAlign: "center",
            marginLeft: "5vw",
            marginRight: "5vw",
            marginTop: "7vh",
          }}
        >
          <p style={{ fontSize: "5vh", margin: "3vh" }}>{practiceContent}</p>
        </div>
        <div className="answer">
          <input
            type="text"
            placeholder="Type your answer here..."
            onChange={handleChange}
            onKeyDown={handleKeyDown} // Add key down handler here
            value={answer}
            style={{
              display: "flex",
              flex: "1",
              fontSize: "4.5vh",
              color: "#ffffff",
              height: "30vh",
              width: "90vw",
              border: "3px solid",
              borderRadius: "25px",
              backgroundColor: check
                ? result
                  ? "#739828"
                  : "#de4d43"
                : "#1A2E23",
              alignContent: "center",
              textAlign: answer ? "center" : "left",
              textIndent: answer ? "0vw" : "28vw",
              marginLeft: "5vw",
              marginRight: "5vw",
              marginTop: "7vh",
              padding: "0",
            }}
          />
        </div>
        <button
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "4.5vh",
            color: "#ffffff",
            height: "10vh",
            width: "30.42vw",
            border: "3px solid",
            borderRadius: "25px",
            backgroundImage: "linear-gradient(to right, #86586A, #EEC7C7)",
            alignContent: "center",
            textAlign: "center",
            marginLeft: "65vw",
            marginRight: "5vw",
            marginTop: "7vh",
            padding: "0",
            cursor: "pointer",
          }}
          onClick={check ? handleNextClick : handleCheck}
        >
          {check ? "Next" : "Check"}
        </button>
      </div>
    </div>
  );
}

export default Practice;
