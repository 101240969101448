import React, { useState } from "react";
import cat from "../img/signup_cat.svg";
import "./signup.css";
import { useNavigate } from "react-router-dom";

function SignUp() {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [buttonHover, setButtonHover] = useState(false);
  const [usernameComment, setUsernameComment] = useState("");
  const [emailComment, setEmailComment] = useState("");
  const [passwordComment, setPasswordComment] = useState("");
  const [confirmPasswordComment, setConfirmPasswordComment] = useState("");
  const [serverError, setServerError] = useState("");
  const navigate = useNavigate();

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleUsername = (event) => {
    setUsername(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
    if (confirmPassword !== event.target.value) {
      setConfirmPasswordComment("Passwords do not match");
    } else {
      setConfirmPasswordComment("");
    }
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
    if (password !== event.target.value) {
      setConfirmPasswordComment("Passwords do not match");
    } else {
      setConfirmPasswordComment("");
    }
  };

  const handleSignUp = async () => {
    if (email) {
      setEmailComment("");
      if (username) {
        setUsernameComment("");
        if (password && password === confirmPassword) {
          const data = { email: email, username: username, password: password };
          try {
            setUsernameComment("");
            setEmailComment("");
            setPasswordComment("");
            setServerError("");
            const response = await fetch("/php/signup.php", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            });

            if (!response.ok) {
              const errorText = await response.text();
              throw new Error(
                `HTTPS error! Status: ${response.status}. Message: ${errorText}`
              );
            }

            const result = await response.text(); // Using text() to get the raw response
            if (result.slice(0, 1) === "u") {
              setUsernameComment(result.slice(1));
            } else if (result.slice(0, 1) === "e") {
              setEmailComment(result.slice(1));
            } else if (result.slice(0, 1) === "p") {
              setPasswordComment(result.slice(1));
            } else if (result.slice(0, 1) === "s") {
              setServerError(result.slice(1));
            } else if (result.slice(0, 1) === "i") {
              localStorage.setItem("session_id", result.substring(result.indexOf("i") + 1, result.indexOf(":")).trim());
              localStorage.setItem("user", result.split(":")[1].trim());
              navigate("/");
            }
          } catch (error) {
            // alert(`An error occurred: ${error.message}`); // Display error to user
          }
        } else if (!password) {
          setPasswordComment("Password field required");
        }
      } else {
        setUsernameComment("Username field required");
      }
    } else {
      setEmailComment("Email field required");
    }
  };

  return (
    <div
      className="animated-signup"
      style={{ display: "flex", flex: "1", flexDirection: "row" }}
    >
      <div
        style={{
          display: "flex",
          flex: "1",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "5vw",
        }}
      >
        <p
          style={{
            color: "#ffffff",
            fontSize: "9vh",
            fontWeight: "700",
            padding: "0",
            margin: "0",
            marginTop: "3vh",
          }}
        >
          CATLINGO
        </p>
        <img
          src={cat}
          alt="login_cat"
          style={{ height: "360px", width: "auto", marginTop: "4vh" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flex: "1",
          flexDirection: "column",
          marginLeft: "10vw",
          marginRight: "5vw",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            border: "3px solid white",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "35px",
            background: "#1A2E23",
            marginTop: "-0.3vh",
            marginBottom: "4vh",
          }}
        >
          <p
            style={{
              color: "#ffffff",
              fontSize: "5vh",
              fontWeight: "700",
              padding: "0",
              margin: "0",
              marginTop: "4vh",
              marginBottom: "2vh",
              textAlign: "center",
            }}
          >
            CREATE YOUR ACCOUNT
          </p>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              className="email"
              id="email"
              type="email"
              placeholder="Email"
              onChange={handleEmail}
              value={email}
              required={true}
              autoComplete="email"
              style={{
                display: "flex",
                fontSize: "3vh",
                color: "#ffffff",
                height: "8vh",
                width: "20vw",
                border: "0px solid",
                borderRadius: "35px",
                backgroundColor: "#294033",
                alignContent: "center",
                textAlign: "left",
                marginLeft: "1vw",
                marginRight: "1vw",
                marginTop: "2vh",
                paddingLeft: "2vw",
                paddingRight: "2vw",
              }}
            />
            {emailComment && (
              <p
                style={{
                  color: "#de4d43",
                  margin: "0",
                  marginTop: "0.4vh",
                  marginBottom: "0vh",
                  fontSize: "1.5vh",
                }}
              >
                {emailComment}
              </p>
            )}
            <input
              className="username"
              id="username"
              type="text"
              placeholder="Username"
              onChange={handleUsername}
              value={username}
              required={true}
              autoComplete="username"
              style={{
                display: "flex",
                fontSize: "3vh",
                color: "#ffffff",
                height: "8vh",
                width: "20vw",
                border: "0px solid",
                borderRadius: "35px",
                backgroundColor: "#294033",
                alignContent: "center",
                textAlign: "left",
                marginLeft: "1vw",
                marginRight: "1vw",
                marginTop: "2vh",
                paddingLeft: "2vw",
                paddingRight: "2vw",
              }}
            />
            {usernameComment && (
              <p
                style={{
                  color: "#de4d43",
                  margin: "0",
                  marginTop: "0.4vh",
                  marginBottom: "0vh",
                  fontSize: "1.5vh",
                }}
              >
                {usernameComment}
              </p>
            )}
            <input
              className="password"
              id="password"
              type="password"
              placeholder="Password"
              onChange={handlePassword}
              value={password}
              required={true}
              autoComplete="new-password"
              style={{
                display: "flex",
                fontSize: "3vh",
                color: "#ffffff",
                height: "8vh",
                width: "20vw",
                border: "0px solid",
                borderRadius: "35px",
                backgroundColor: "#294033",
                alignContent: "center",
                textAlign: "left",
                marginLeft: "1vw",
                marginRight: "1vw",
                marginTop: "2vh",
                paddingLeft: "2vw",
                paddingRight: "2vw",
              }}
            />
            {passwordComment && (
              <p
                style={{
                  color: "#de4d43",
                  margin: "0",
                  marginTop: "0.4vh",
                  marginBottom: "0vh",
                  fontSize: "1.5vh",
                }}
              >
                {passwordComment}
              </p>
            )}
            <input
              className="confirmPassword"
              id="confirmpassword"
              type="password"
              placeholder="Confirm Password"
              onChange={handleConfirmPassword}
              value={confirmPassword}
              required={true}
              autoComplete="new-password"
              style={{
                display: "flex",
                fontSize: "3vh",
                color: "#ffffff",
                height: "8vh",
                width: "20vw",
                border: "0px solid",
                borderRadius: "35px",
                backgroundColor: "#294033",
                alignContent: "center",
                textAlign: "left",
                marginLeft: "1vw",
                marginRight: "1vw",
                marginTop: "2vh",
                paddingLeft: "2vw",
                paddingRight: "2vw",
              }}
            />
            {confirmPasswordComment && (
              <p
                style={{
                  color: "#de4d43",
                  margin: "0",
                  marginTop: "0.5vh",
                  marginBottom: "-2.2vh",
                  fontSize: "1.5vh",
                }}
              >
                {confirmPasswordComment}
              </p>
            )}
          </form>
          <button
            onClick={handleSignUp}
            onMouseEnter={() => setButtonHover(true)}
            onMouseLeave={() => setButtonHover(false)}
            style={{
              display: "flex",
              fontSize: "4vh",
              fontWeight: "600",
              color: buttonHover ? "#ffffff" : "#dadada",
              height: "8vh",
              width: "20vw",
              border: "3px solid",
              borderRadius: "35px",
              backgroundColor: buttonHover ? "#c493ac" : "#8c687a",
              alignContent: "center",
              textAlign: "center",
              marginLeft: "3vw",
              marginRight: "3vw",
              marginTop: "4vh",
              marginBottom: "4vh",
              padding: "0",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            SIGN UP
          </button>
          {/* {serverError && <p style={{color: "#de4d43", margin: "0", marginTop: "0.4vh", marginBottom:"0vh", fontSize: "1.5vh"}}>{serverError}</p>} */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            border: "3px solid white",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "35px",
            background: "#1A2E23",
            color: "white",
            fontSize: "3vh",
          }}
        >
          <p style={{ paddingRight: "1vw" }}>Already have an account?</p>
          <a href="/login" style={{ color: "#b9a", cursor: "pointer" }}>
            Log In
          </a>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
