import React, { useEffect, useState } from "react";

function UserIP() {
  const [ip, setIP] = useState("");

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch("/php/getip.php");
        const data = await response.text();
        setIP(data);
      } catch (error) {
        console.error("Error fetching the IP address:", error);
      }
    };

    fetchIP();
  }, []);

  // Inline styles for the keyframe animation and centering
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // Full height of the viewport
    backgroundColor: "#000000", // Optional background color
  };

  const textStyle = {
    fontSize: "3em",
    fontWeight: "bold",
    animation: "colorCycle 5s infinite", // Animation name and duration
  };

  const keyframesStyle = `
    @keyframes colorCycle {
      0% { color: red; }
      25% { color: orange; }
      50% { color: yellow; }
      75% { color: green; }
      100% { color: blue; }
    }
  `;

  return (
    <div style={containerStyle}>
      {/* Injecting keyframes via a style tag */}
      <style>{keyframesStyle}</style>
      <h1 style={textStyle}>{ip}</h1>
    </div>
  );
}

export default UserIP;
