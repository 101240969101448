import React, { useState, useEffect } from "react";
import "./lessons.css";
import { FaPlus } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

function LessonComponent({ lessonNumber, title, language }) {
  const navigate = useNavigate();
  
  const handleClick = () => {
    const formattedTitle = title.toLowerCase().replace(/\s+/g, '-'); // Format title for URL
    navigate(`/${language}/study/${formattedTitle}`, { state: { lessonNumber, title } }); // Pass lessonNumber and title as state
  };
  return (
    <div
      className="lesson"
      onClick={handleClick}
      style={{
        display: "flex",
        flex: "1",
        flexDirection: "column",
        border: "solid 2px",
        backgroundColor: "#1A2E23",
        borderRadius: "35px",
        padding: "0.5em",
        paddingBottom: "0.7em",
        paddingRight: "1em",
        paddingLeft: "1em",
        margin: "2em",
        marginRight: "1em",
        marginLeft: "1em",
        justifyContent: "center",
        alignItems: "center",
        height: "8em",
        width: "16em",
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = "#315742"; // Change background on hover
        e.currentTarget.style.transform = "scale(1.05)"; // Slight zoom effect
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = "#1A2E23"; // Revert background on hover end
        e.currentTarget.style.transform = "scale(1)"; // Revert zoom effect
      }}
    >
      <h4 style={{ marginTop: "0.5em", marginBottom: "0.2em" }}>
        Lesson #{lessonNumber}:
      </h4>
      <h3
        style={{
          textAlign: "center",
          fontSize: "2em",
          marginTop: "0em",
          marginBottom: "0.3em",
        }}
      >
        {title}
      </h3>
    </div>
  );
}

function Lessons() {
  const { language } = useParams();
  const navigate = useNavigate();

  const [lessonData, setLessonData] = useState([]);
  const [error, setError] = useState(null);

  const handleAddContentClick = () => {
    navigate(`/${language}/addcontent`); // Redirect to /{language}/addcontent
  };

  const fetchLessons = async () => {
    try {
      const response = await fetch('/php/lessons.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ language }),
      });
  
      if (!response.ok) {
        const errorData = await response.json(); // Try to get error details
        throw new Error(`Network response was not ok: ${errorData.error || 'Unknown error'}`);
      }
  
      const data = await response.json();
      setLessonData(data); // Set the fetched lessons to state
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchLessons(); // Call the fetch function when the component mounts
  }, [language]); // Fetch lessons whenever the language parameter changes

  return (
    <div
      className="animated-lessons"
      style={{
        color: "#ffffff",
        padding: "1em",
        display: "flex",
        flex: "1",
        width: "100%",
        margin: "0",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="title"
        style={{
          display: "flex",
        }}
      >
        <h1 style={{ fontSize: "5.5em", marginBottom: 0, marginTop: "0.2em" }}>
          LESSONS
        </h1>
      </div>

      <div
        className="lessons"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowY: "scroll",
          paddingBottom: "1em",
          marginBottom: "4em",
          marginRight: "5em",
        }}
      >
        {lessonData.map((lesson, index) => (
          <div key={index} className="lesson_rows" style={{ display: "flex", flexDirection: "row" }}>
            {lessonData.slice(index * 4, index * 4 + 4).map((lesson, lessonIndex) => (
              <LessonComponent
                key={lessonIndex}
                lessonNumber={lesson.lessonNumber}
                title={lesson.title}
                language={language} // Pass down the language prop
              />
            ))}
          </div>
        ))}
        <button
            onClick={handleAddContentClick}
            style={{
              position: "fixed",
              bottom: "1em",
              right: "1em",
              fontSize: "2em",
              borderRadius: "50%",
              height: "3em",
              width: "3em",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundImage: "linear-gradient(to right, #86586A, #EEC7C7)",
              color: "white",
              border: "none",
              cursor: "pointer",
              backgroundSize: "200% 200%",
              transition: "transform 0.3s ease, background-position 0.3s ease", // Transition for smooth effect
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.1)"; // Slightly enlarge on hover
              e.currentTarget.style.animation = "glow 3s infinite linear"; // Start glowing animation
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)"; // Revert to original size
              e.currentTarget.style.animation = "none"; // Stop glowing animation
            }}
          >
            <FaPlus />
          </button>

          <style>
            {`
              @keyframes glow {
                0% {
                  background-position: 0% 50%;
                }
                50% {
                  background-position: 100% 50%;
                }
                100% {
                  background-position: 0% 50%;
                }
              }
            `}
        </style>
      </div>
    </div>
  );
}

export default Lessons;
