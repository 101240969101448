import React, { useState, useEffect } from "react";
import "./study.css";
import { useParams, useNavigate, useLocation } from "react-router-dom";

function ContentComponent({ w, en, ex, exen }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        fontSize: "4vh",
        marginBottom: "3vh",
      }}
    >
      <div
        style={{
          marginRight: "6vh",
          marginLeft: "6vh",
          display: "flex",
          flex: "1",
          flexDirection: "row",
          borderWidth: "3px",
          borderColor: "#ffffff",
          border: "solid",
          borderBottomWidth: "0px",
          paddingLeft: "5vh",
          background: "#3D6C54",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: "600", margin: "0 1vw 0 0" }}>{w}</p>
        <p style={{ fontWeight: "800", margin: "0 1vw 0 0 " }}>-</p>
        <p>{en}</p>
      </div>
      <div
        style={{
          marginRight: "6vh",
          marginLeft: "6vh",
          display: "flex",
          flex: "1",
          borderWidth: "3px",
          borderColor: "#ffffff",
          border: "solid",
          paddingLeft: "2vh",
          flexDirection: "column",
          background: "#1A2E23",
        }}
      >
        <p
          style={{
            margin: "3vh",
            marginBottom: "0",
          }}
        >
          {ex}
        </p>
        <p
          style={{
            margin: "3vh",
            fontSize: "3vh",
          }}
        >
          {exen}
        </p>
      </div>
    </div>
  );
}

function Study() {
  const [studyContent, setStudyContent] = useState([]);
  const { language, lessonName } = useParams(); // Get lesson name from URL
  const navigate = useNavigate(); // Hook to navigate programmatically
  const [practiceLanguage, setPracticeLanguage] = useState(0);

  const location = useLocation();
  const { lessonNumber, title } = location.state || {};

  const fetchContent = async () => {
    try {
      const response = await fetch("/php/study.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ language, lesson_ID: lessonNumber }),
      });

      if (!response.ok) {
        const errorData = await response.json(); // Try to get error details
        throw new Error(
          `Network response was not ok: ${errorData.error || "Unknown error"}`
        );
      }

      const data = await response.json();
      setStudyContent(data); // Set the fetched lessons to state
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchContent(); // Call the fetch function when the component mounts
  }, [language, lessonNumber, title]); // Fetch lessons whenever the language parameter changes

  const handlePracticeClick = () => {
    navigate(`/${language}/practice/${lessonName}`, {
      state: { lessonNumber, title, ex_ID: 1 },
    }); // Redirect to practice page with lesson name
  };

  const handleEnglishClick = () => {
    setPracticeLanguage((prevLanguage) => {
      return prevLanguage === 1 ? 0 : 1; // prevLanguage holds the previous state value
    });
  }

  const handleLanguageClick = () => {
    setPracticeLanguage((prevLanguage) => {
      return prevLanguage === 2 ? 0 : 2; // prevLanguage holds the previous state value
    });
    console.log(practiceLanguage);
  }

  return (
    <div
      className="animated-study"
      style={{
        color: "#ffffff",
        padding: "1em",
        display: "flex",
        flex: "1",
        width: "100%",
        margin: "0",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="title"
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 2em",
        }}
      >
        <h2
          style={{
            fontSize: "8vh",
            marginTop: "0.2em",
            alignSelf: "center",
            marginLeft: "4vh",
          }}
        >
          LESSON #{lessonNumber}:{" "}
          {lessonName.replace(/-/g, " ").replace(/^\w/, (c) => c.toUpperCase())}
        </h2>
        <div style={{ display: "flex", flex: "1", flexDirection: "column"}}>
        <button
          onClick={handlePracticeClick} // Handle button click to redirect
          style={{
            borderRadius: "25px",
            height: "8vh",
            fontSize: "6vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: "linear-gradient(to right, #86586A, #EEC7C7)",
            color: "white",
            border: "solid 2px",
            padding: "0.6em",
            paddingTop: "0.8em",
            paddingBottom: "0.8em",
            cursor: "pointer",
            marginBottom: "1vh",
            marginRight: "8vh",
          }}
        >
          PRACTICE
        </button>
        <div style={{ display: "flex", flex: "1", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%"}}>
        <button
          onClick={handleEnglishClick} // Handle button click to redirect
          style={{
            width: "100%",
            borderRadius: "25px",
            height: "7vh",
            fontSize: "6vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: "linear-gradient(to right, #86586A, #EEC7C7)",
            color: "white",
            border: "solid 2px",
            padding: "0.6em",
            paddingTop: "0.8em",
            paddingBottom: "0.8em",
            cursor: "pointer",
            marginBottom: "4vh",
            marginRight: "8vh",
            fontSize: "2vh"
          }}
        >
          English
        </button>
        <button
          onClick={handleLanguageClick} // Handle button click to redirect
          style={{
            width: "100%",
            borderRadius: "25px",
            height: "7vh",
            fontSize: "6vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: practiceLanguage === 2 ? "linear-gradient(to right, #86586A, #EEC7C7)" : "grey",
            color: "white",
            border: "solid 2px",
            padding: "0.6em",
            paddingTop: "0.8em",
            paddingBottom: "0.8em",
            cursor: "pointer",
            marginBottom: "4vh",
            marginRight: "8vh",
            fontSize: "2vh"
          }}
        >
          Filipino
        </button>
        </div>
        </div>
      </div>
      <div
        className="content"
        style={{
          display: "flex",
          flex: "1",
          width: "100%",
          flexDirection: "column",
          overflow: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          paddingBottom: "1em",
          marginBottom: "4em",
          marginRight: "2em",
        }}
      >
        {studyContent.length > 0 ? (
          studyContent.map((content, index) => (
            <ContentComponent
              key={index}
              w={content.word}
              en={content.english}
              ex={content.example}
              exen={content.english_example}
            />
          ))
        ) : (
          <p>Loading content...</p> // Display loading message when data is being fetched
        )}
      </div>
    </div>
  );
}

export default Study;
