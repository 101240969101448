// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fadeInRadialGradient {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animated-signup {
  height: 100vh;
  width: 100%;
  background: radial-gradient(circle at 25vw 60vh, #20392c 10%, #141416);
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.animated-signup::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at 25vw 60vh,
    #86586a 6%,
    #20392c 10%,
    #141416 18%,
    #141416 19%
  );
  background-size: 300vw 300vh;
  background-repeat: repeat;
  opacity: 0;
  z-index: -1;
  animation: fadeInRadialGradient 5s ease-in-out forwards;
}

input:focus {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/signup.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,aAAa;EACb,WAAW;EACX,sEAAsE;EACtE,kBAAkB;EAClB,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ;;;;;;GAMC;EACD,4BAA4B;EAC5B,yBAAyB;EACzB,UAAU;EACV,WAAW;EACX,uDAAuD;AACzD;;AAEA;EACE,aAAa;AACf","sourcesContent":["@keyframes fadeInRadialGradient {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n.animated-signup {\n  height: 100vh;\n  width: 100%;\n  background: radial-gradient(circle at 25vw 60vh, #20392c 10%, #141416);\n  position: relative;\n  margin: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 0;\n}\n\n.animated-signup::before {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: radial-gradient(\n    circle at 25vw 60vh,\n    #86586a 6%,\n    #20392c 10%,\n    #141416 18%,\n    #141416 19%\n  );\n  background-size: 300vw 300vh;\n  background-repeat: repeat;\n  opacity: 0;\n  z-index: -1;\n  animation: fadeInRadialGradient 5s ease-in-out forwards;\n}\n\ninput:focus {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
